import { createGlobalStyle } from 'styled-components';

import theme from './theme';

type Theme = typeof theme;

const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  body {
    margin: 0;
    padding: 0;
    background: ${props => props.theme.colors.lightgrey};
    overflow-x: hidden;
  }

  html {
    font-family: Merriweather, -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  }

  div, header, footer {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  .router {
    width: 100%;
  }
`;

export { GlobalStyles };
