import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const AnimatedDiv = (props: any) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: props.threshold });

  const variants = {
    hidden: { opacity: props.initialOpacity || 0, y: props.initialY || 100 },
    visible: { opacity: 1, y: 0 },
  };

  const transition = {
    easeIn: [0.17, 0.67, 0.83, 0.67],
    duration: 0.5,
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      className={props.className}
      transition={transition}
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
    >
      {props.children}
    </motion.div>
  )
}

export { AnimatedDiv };
