import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const Title = styled(motion.h1)<any>`
    font-family: 'Merriweather', serif;
    margin: 0;
    padding: 0;
    font-size: ${(props) => props.theme.fontSize.title}px;
    font-weight: bold;
    -webkit-text-stroke: 3px ${(props) => props.theme.colors.white};
    paint-order: stroke fill;
    color: ${(props) => props.theme.colors.darkred};
`;

type SizeType = {
    size?: number;
    mt?: number;
    mb?: number;
    m?: number;
    color?: string;
};
const Headline = styled(motion.h2)<SizeType>`
  margin: 0;
  font-size: ${(props) => props.theme.fontSize.xxxl}px;
  font-weight: bold;
  color: ${(props) => props.color || props.theme.colors.darkgrey};

  ${(props) =>
      props.size &&
      css`
          font-size: ${props.size}px;
      `}

  ${(props) =>
      props.mt !== undefined &&
      css`
          margin-top: ${props.mt}px;
      `}

  ${(props) =>
      props.mb !== undefined &&
      css`
          margin-bottom: ${props.mb}px;
      `}
`;

const SecondaryHeadline = styled.h3<SizeType>`
  margin: 10px 0;
  font-family: Lato;
  font-size: ${(props) => props.theme.fontSize.lg}px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.33px;
  color: ${(props) => props.theme.colors.grey};
  text-transform: uppercase;

  ${(props) =>
      props.size &&
      css`
          font-size: ${props.size}px;
      `}

  ${(props) =>
      props.m !== undefined &&
      css`
          margin: ${props.m}px;
      `}

  ${(props) =>
      props.mt !== undefined &&
      css`
          margin-top: ${props.mt}px;
      `}

  ${(props) =>
      props.mb !== undefined &&
      css`
          margin-bottom: ${props.mb}px;
      `}
`;

type ParagraphType = {
    p?: number;
    m?: number;
    mb?: number;
    mt?: number;
    color?: string;
    center?: boolean;
    uppercase?: boolean;
    fontFamilySans?: boolean;
    bold?: boolean;
    size?: number;
    fontStyle?: string;
    animate?: string;
    initial?: string;
    transition?: any;
    variants?: any;
};
const Paragraph = styled(motion.p)<ParagraphType>`
  color: ${(props) => props.color || props.theme.colors.darkgrey};
  font-size: ${(props) => props.theme.fontSize.xl}px;
  padding: ${(props) => (props.p ? `${props.p}px` : 0)};
  margin: ${(props) => (props.m ? `${props.m}px` : 0)};
  margin-bottom: ${(props) => (props.mb ? `${props.mb}px` : 0)};
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : 0)};

  ${(props) =>
      props.center &&
      css`
          text-align: center;
      `}

  ${(props) =>
      props.size &&
      css`
          font-size: ${props.size}px;
      `}

  ${(props) =>
      props.fontStyle &&
      css`
          font-style: ${props.fontStyle};
      `}

  ${(props) =>
      props.fontFamilySans &&
      css`
          font-family: 'Lato', sans-serif;
      `}

  ${(props) =>
      props.bold &&
      css`
          font-weight: bold;
      `}

  ${(props) =>
      props.uppercase &&
      css`
          text-transform: uppercase;
      `}
`;

type CaptionType = {
    mt?: number;
    mb?: number;
    bold?: boolean;
};
const Caption = styled.p<CaptionType>`
  font-size: ${(props) => props.theme.fontSize.md}px;
  line-height: 1.93;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 40px;

  ${(props) =>
      props.mt !== undefined &&
      css`
          margin-top: ${props.mt}px;
      `}

  ${(props) =>
      props.mb !== undefined &&
      css`
          margin-bottom: ${props.mb}px;
      `}

  ${(props) =>
      props.bold &&
      css`
          font-weight: bold;
      `}
`;

type ItemType = {
    mt?: number;
    mb?: number;
    bold?: boolean;
};
const Item = styled.p<ItemType>`
  font-size: ${(props) => props.theme.fontSize.sm}px;
  line-height: 1.93;
  font-family: 'Lato', sans-serif;
  color: rgba(0, 0, 0, 0.7);

  ${(props) =>
      props.mt !== undefined &&
      css`
          margin-top: ${props.mt}px;
      `}

  ${(props) =>
      props.mb !== undefined &&
      css`
          margin-bottom: ${props.mb}px;
      `}

  ${(props) =>
      props.bold &&
      css`
          font-weight: bold;
      `}
`;

export { Title, Headline, SecondaryHeadline, Paragraph, Caption, Item };
