export default {
  screens: {
    sm: 640,
    md: 960,
    lg: 1280,
    alt: 840,
  },
  fontSize: {
    xxs: 8,
    xs: 10,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 18,
    xxl: 24,
    xxxl: 28,
    subtitle: 50,
    title: 130
  },
  colors: {
    white: "#ffffff",
    red: "#f88181",
    darkred: "#ff5c5c",
    green: "#00c27c",
    darkgreen: "#327f32",
    blue: "#8ba9db",
    darkblue: "#48668e",
    darkestblue: "#395171",
    pink: "#d98ec6",
    lightgrey: "#eeeeee",
    grey: "#888888",
    darkgrey: "#3d434c",
    mostard: "#d5bc52",
  },
  opacity: {
    0: 0,
    25: 0.25,
    50: 0.5,
    75: 0.75,
    100: 1,
  },
  py: (value: number | string) =>
    `padding-top: ${value}; padding-bottom: ${value};`,
  // Add anything else you'd like.
};
