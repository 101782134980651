import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../images/gleanly-logo-reverse.svg';
import { getAppHost } from '../../urlHelper';

const StyledMenuBar = styled.div`
    position: relative;
    top: 0;
    width: 100%;
`;

const LogoImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        left: 20px;
    }
`;

const Menu = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;

    a {
        font-family: 'Lato', sans-serif;
        color: white;
        font-family: Lato;
        font-size: ${(props) => props.theme.fontSize.md}px;
        text-decoration: none;
        display: flex;
        align-items: center;
        margin-left: 20px;

        :hover {
            text-decoration: underline;
        }

        .fa-power-off {
            height: 10px;
            width: 10px;
            border-radius: 10px;
            margin-left: 10px;
            color: ${(props) => props.theme.colors.darkred};
            background-color: rgba(0, 0, 0, 0.25);
            padding: 3px;
        }
    }

    .display-large {
        display: none;

        @media (min-width: ${(props) => props.theme.screens.sm}px) {
            display: block;
        }
    }

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        right: 20px;
    }
`;

const MenuBar = () => {
    const [url, setUrl] = useState('');

    useEffect(() => {
        setUrl(getAppHost());
    }, []);

    const menu = (
        <Menu>
            <a href={`${url}/signup`} target="_blank" rel="noopener noreferrer">
                Start a repository
            </a>
            <Link className="display-large" to="/package">
                Pricing
            </Link>
            <a
                className="help-contact"
                href="https://gleanly.productfruits.help/en/article/get-in-touch"
                target="_blank"
                rel="noopener noreferrer"
            >
                Get in touch
            </a>
            <a href={url} target="_blank" rel="noopener noreferrer">
                <b>Login</b>
                <FontAwesomeIcon icon={'power-off'} />
            </a>
        </Menu>
    );

    return (
        <StyledMenuBar>
            <Link to="/">
                <LogoImage src={logo} />
            </Link>
            {menu}
        </StyledMenuBar>
    );
};

export { MenuBar };
