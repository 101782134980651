import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Flex } from '@glean/component-library/build/serverSide';

import logo from '../../images/gleanly-logo.svg';
import { useTheme } from '../../styles';
import { ColoredRow } from '../colored-row/colored-row';
import { Paragraph } from '../typography';

const StyledFooter = styled.footer`
    width: 100%;
    padding: 40px;
    background-color: ${(props) => props.theme.colors.white};

    p {
        text-align: center;
    }

    a {
        font-family: 'Lato', sans-serif;
        font-family: Lato;
        font-size: ${(props) => props.theme.fontSize.sm}px;
        text-decoration: none;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        color: #48668e;

        :hover {
            text-decoration: underline;
            color: #48668e;
        }

        :visited {
            color: #48668e;
        }
    }

    .display-large {
        display: none;
    }

    .small {
        display: flex;
    }

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        .display-large {
            display: flex;
        }

        .small {
            display: none;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const VerticalLine = styled.div`
    height: 100%;
    min-height: 80px;
    border-left: 1px solid ${(props) => props.theme.colors.lightgrey};

    @media (min-width: ${(props) => props.theme.screens.sm}px) {
        margin: 0 40px;
    }
`;

const Footer: React.FC = () => {
    const { grey } = useTheme().colors;
    const { xs } = useTheme().fontSize;
    return (
        <Fragment>
            <ColoredRow />
            <StyledFooter>
                <Flex
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <Flex
                        className="display-large"
                        minWidth={430}
                        mb={40}
                        mr={40}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <img src={logo} alt="logo" />
                        <VerticalLine />
                        <Paragraph color={grey} uppercase size={xs} bold fontFamilySans>
                            making knowledge usable
                        </Paragraph>
                    </Flex>
                    <Flex
                        className="small"
                        mr={40}
                        mb={40}
                        flexDirection="column"
                        alignItems="center"
                    >
                        <img src={logo} alt="logo" />
                        <Paragraph mt={40} color={grey} uppercase size={xs} bold fontFamilySans>
                            making knowledge usable
                        </Paragraph>
                    </Flex>
                    <Flex flexDirection="column" mb={40} mr={40}>
                        <a href="/terms" target="_blank">
                            Terms & Conditions
                        </a>
                        <a href="/privacy" target="_blank">
                            Privacy
                        </a>
                        <a
                            className="help-contact"
                            href="https://gleanly.productfruits.help/en/article/get-in-touch"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Get in touch
                        </a>
                    </Flex>
                    <Paragraph mb={40} color={grey} size={xs} fontFamilySans>
                        <b>© Glean.ly Limited.</b> 15 Ptarmigan Heights, Reading, England, RG12 8AB
                        - Registered in England, Company Number. 11302009.
                    </Paragraph>
                </Flex>
            </StyledFooter>
        </Fragment>
    );
};

export { Footer };
