import styled, { css } from "styled-components";

type ButtonType = {
  color: string;
  bg: string;
  m?: any;
}
const Button = styled.button<ButtonType>`
  height: 40px;
  border-radius: 15px;
  background-color: ${props => props.bg};
  color: ${props => props.color};
  padding: 10px 20px;
  margin-top: 20px;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  font-family: Lato;
  font-size: ${props => props.theme.fontSize.sm}px;
  cursor: pointer;
  outline: none;

  > :last-child {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.8;
  }

  ${props => props.m && css`
    margin: ${props.m};
  `}
`;

export { Button };
