import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme, GlobalStyles } from "../../styles";
// Components
import { Footer } from "../footer";
import { SEO } from "../seo";

const Container = styled.div<{ mb?: number }>`
  margin: 0 auto;
  height: 100%;
  min-width: 320px;
  margin-bottom: ${props => props.mb || '0'}px;
  overflow-x: hidden;

  .large {
    display: none;
  }

  @media (min-width: ${props => props.theme.screens.lg}px) {
    .small {
      display: none;
    }

    .large {
      display: block;
    }
  }
`;

const Layout = (props: any) => {
  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <GlobalStyles />
      <Container mb={props.mb}>
        {props.children}
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export { Layout };
