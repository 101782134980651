import React from "react";
import styled from "styled-components";

import { useTheme } from "../../styles";
import { Paragraph } from "../typography";

const ColoredBox = styled.div`
    min-width: 280px;
    padding: 40px;
    padding-bottom: 60px;
    background-color: ${(props) => props.color};
    color: white;
    line-height: 1.67;

    @media (min-width: ${(props) => props.theme.screens.md}px) {
        min-width: 340px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
`;

const paragraphProps = {
    color: 'white',
    m: 0,
    mb: 20,
    size: 18,
    fontStyle: 'italic',
};

const authorProps = {
    color: 'rgba(0, 0, 0, 0.5)',
    bold: true,
    uppercase: true,
    fontFamilySans: true,
    size: 12,
};

export const ColoredRow = () => {
    const { blue, green, red, pink, mostard } = useTheme().colors;

    return (
        <Row>
            <ColoredBox color={pink}>
                <Paragraph {...paragraphProps}>
                    “Glean.ly is a huge timesaver for us, and it’s light, easy to use, and scalable.
                    A tool built for its purpose!”
                </Paragraph>
                <Paragraph {...authorProps}>Michael, UX Manager, Adobe</Paragraph>
            </ColoredBox>
            <ColoredBox color={mostard}>
                <Paragraph {...paragraphProps}>
                    “It has been particularly helpful in focusing our team. The framework has helped
                    elevate the status of research in our company... it has been a game changer
                    across the board and ultimately helped us all make far better product
                    decisions.”
                </Paragraph>
                <Paragraph {...authorProps}>James, UX/UI Designer, Incrowd Sports</Paragraph>
            </ColoredBox>
            <ColoredBox color={blue}>
                <Paragraph {...paragraphProps}>
                    “We’ve been using Atomic Research since 2017... it has really helped us as a
                    decentralised team. We’re all really excited to use Glean.ly”
                </Paragraph>
                <Paragraph {...authorProps}>
                    Chiara, UX Lead & Design Professor, Mail Up Group
                </Paragraph>
            </ColoredBox>
            <ColoredBox color={green}>
                <Paragraph {...paragraphProps}>
                    “Atomic UX Research forces evidence-based thinking... Another advantage is that
                    it is scalable... Having a clear process allows stakeholders to understand and
                    contribute to the creation of sound knowledge.”
                </Paragraph>
                <Paragraph {...authorProps}>
                    Pedro Almeida, Deloitte/Wingman on behalf of the European Commission
                </Paragraph>
            </ColoredBox>
            <ColoredBox color={red}>
                <Paragraph {...paragraphProps}>
                    “Glean.ly is the only product that delivers our research needs.”
                </Paragraph>
                <Paragraph {...authorProps}>Mehdi Aoussat, UX Researcher, RAJA</Paragraph>
            </ColoredBox>
            <ColoredBox color={pink}>
                <Paragraph {...paragraphProps}>
                    “We have used Atomic Research with many tests, but with the Glean.ly repository
                    we can achieve even more interesting results.”
                </Paragraph>
                <Paragraph {...authorProps}>
                    Sydney Neto, UA Portal Evaluation Team, University of Aveiro
                </Paragraph>
            </ColoredBox>
            <ColoredBox color={mostard}>
                <Paragraph {...paragraphProps}>
                    “Glean.ly is the first user research tool that has been designed with the needs
                    of large companies in mind”
                </Paragraph>
                <Paragraph {...authorProps}>
                    Adam Radziszewski, Design Strategist, Inovalon
                </Paragraph>
            </ColoredBox>
            <ColoredBox color={blue}>
                <Paragraph {...paragraphProps}>
                    “Glean.ly is getting a heck of a lot people in our company excited about
                    research collaboration. It is so satisfying that they immediately understand
                    that this will fundamentally change our business”
                </Paragraph>
                <Paragraph {...authorProps}>Martin Bulmer, Lead UX Researcher, Trade Me</Paragraph>
            </ColoredBox>
        </Row>
    );
};
