import styled, { css } from "styled-components";
import { AnimatedDiv } from "../animated-div";

const Box = styled(AnimatedDiv) <{ mt?: number, mb?: number, m?: number, threshold?: number }>`
  background-color: ${props => props.theme.colors.white};
  padding: 40px;
  margin: 40px 20px;
  border-radius: 40px;
  border-top-left-radius: 0;
  position: relative;
  max-width: 1120px;

  ${props =>
    props.m !== undefined &&
    css`
      margin: ${props.m}px;
    `};

  ${props =>
    props.mt &&
    css`
      margin-top: ${props.mt}px;
    `};

  ${props =>
    props.mb &&
    css`
      margin-bottom: ${props.mb}px;
    `};

  @media (min-width: ${props => props.theme.screens.sm}px) {
    padding: 80px;
    margin: 80px;

    ${props =>
    props.mt &&
    css`
        margin-top: ${props.mt}px;
      `};
  }

  @media (min-width: ${props => props.theme.screens.md}px) {
    & > .headers {
      max-width: 780px;
      margin-left: auto;
    }
  }
`;

export { Box };
